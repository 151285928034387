import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Box, { BoxParagraph, BoxTitle, BoxButton } from '../elements/box';
import Diapo from '../elements/diapo';
import Card, { CardContent, CardImage, CardTitle} from '../elements/card';

const IndexPage = () => 
    <Layout>
        <div className="columns">
            <div className="column">
                <Card background="primary-light">
                    <div className="columns">
                        <div className="column is-3">
                            <CardContent>
                                <Diapo
                                    backgroundImages={[ 
                                        "redimension1",
                                        "redimension2",
                                        "redimension3",		
                                    ]}
                                    alt="portrait"
                                    size="is-4by5"
                                />
                            </CardContent>
                        </div>
                        <div className="column">
                            <CardContent>
                                <CardTitle title="Bonjour, je suis Anne DINDAR VALIENTE."/>
                            </CardContent>
                            <div className="columns">
                                <div className="column">
                                    <div className="card-content">
                                        <div className="content centered-content">
                                            <p>Superviseure, thérapeute, pédagogue et formatrice depuis 25 ans, sensibilisée aux 
                                            particularités liées à l'interculturel, par de nombreuses années passées à l'étranger 
                                            et passionnée par l'aventure humaine, je me considère comme un "artisan de la rencontre". 
                                            Ce qui me caractérise c'est le " goût de l'autre".</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="column">
                                    <CardContent>
                                        <Card>
                                            <CardImage src="plaque" alt="plaque" size="is-5by3" />
                                        </Card>
                                    </CardContent>
                                </div>
                            </div>
                        </div>
                     </div>
                </Card>
            </div>
        </div>
        <Box>
            <div className="columns">
                <div className="column">
                    <BoxTitle title="Mes spécificités en thérapie:"/>
                    <div className="columns">
                        <div className="column">
                            <BoxParagraph>
                                <p>Désordres émotionnels,</p>
                                <p>Difficultés relationnelles (personnelle, en couple, en famille et professionnelle),</p>
                                <p> Séparation et deuil,
                                    Conduites addictives,
                                    Troubles de l’image,
                                    Abus,
                                    Régulation émotionnelle,
                                    Processus corporels,
                                    Burn out,
                                    Angoisses existentielles,</p>
                                <p>Personnalité <a href="https://les-tribulations-dun-petit-zebre.com">atypique</a>.</p>
                            </BoxParagraph>
                        </div>
                        <div className="column">
                            <BoxParagraph>
                                <p>J'accompagne des individuels(ados, adultes), des couples et des familles.</p>
                                <p>J'anime des groupes continus et ponctuels en résidentiels.</p>
                            </BoxParagraph>
                                <div className="buttons is-centered">
                                    <Link to='/therapie_individuelle'><BoxButton>La thérapie individuelle</BoxButton></Link>
                                    <Link to='/therapie_de_couple'><BoxButton>La thérapie de couple</BoxButton></Link>
                                    <Link to='/therapie_familiale'><BoxButton>La thérapie familiale</BoxButton></Link>
                                    <Link to='/therapie_en_groupe'><BoxButton>La thérapie en groupe</BoxButton></Link>
                                </div>
                        </div>
                    </div>
                    <Box background="primary-light">
                        <BoxParagraph>
                            <p><em>Thérapie possible en langue française et espagnole, en visio comme en présentiel.</em></p>
                        </BoxParagraph>
                    </Box>
                </div>
                <div className="column">
                    <BoxTitle title="Cabinet de Psychothérapie"/>
                    <br />
                    <Diapo
                        backgroundImages={[ 
                            "cabinet1",
                            "cabinet3",
                            "cabinet4",
                            "cabinet5",
                            "cabinet6",
                            "cabinet7",			
                        ]}
                        alt="cabinet"
                        size="is-4by3"
                    />
                </div>
            </div>
        </Box>
        <Box background="primary-light">
            <BoxTitle title="Titulaire du CEP : Certificat Européen de Psychothérapie "/>
            <BoxParagraph>
                <p>Le CEP vise à contribuer à la mise en place de la profession de psychothérapeute et à instaurer
                    un niveau de formation élevé d'un pays à l'autre (3200 heures, en 7 ans d'études - dont 3 ans 
                    en sciences humaines et 4 ans et plus dans une méthode reconnue de la psychothérapie). Il doit 
                    favoriser l'échange de professionnels entre les pays d'Europe et il est encouragé, à ce titre, 
                    par la Commission de Bruxelles (le "Gouvernement" européen). Il est actuellement admis par les 
                    représentants des organismes de psychothérapie de 41 pays d'Europe.</p>
                <p>Je suis affiliée à la <a href="http://www.ff2p.fr/fichiers_site/accueil/accueil.html">FF2P</a> : la 
                    Fédération Française de Psychothérapie et Psychanalyse, et à l’<a href="https://www.eagt.org/joomla/index.php/home-gpo/gestalt-therapists">
                    EAGT</a> : European Association Gestalt Therapy.</p>
            </BoxParagraph>
        </Box>
        <div className="column">
            <Card>
                <CardImage src="illustration1" alt="illustration"/>
            </Card>
        </div>
        <Box>
            <p className="title is-4 is-family-secondary centered-content">Superviseure certifiée par l'
                <a href="https://www.epg-gestalt.fr">EPG</a></p>
            <BoxParagraph>
                <p>Je forme et supervise la pratique de psycho praticiens en individuel et en groupe.</p>
                <div className="columns">
                    <div className="column">
                        <p><em>-Supervision individuelle en 1h</em></p>
                        <p><em>-Groupe de supervision en journée de 6 personnes de 9h30 à 17h</em></p>
                    </div>
                    <div className="column">
                        <p><em>-Groupe de supervision en demi-journée de 4 personnes de 9h à 13h</em></p>
                        <p><em>-Groupe de supervision de thérapeute de couple en demi- journée de 4 personnes de 9h à 13h</em></p>
                    </div>
                </div>
            </BoxParagraph>
        </Box>
        <Box background="primary-light">
            
            <div className="columns">
                <div className="column is-one-third">
                    <Diapo
                        backgroundImages={[ 
                            "nformation1",
                            "nformation2",
                            "nformation3",
                            "formation_AYANA",
                            "formation_AYANA_2",
                            "formation_AYANA_3"
                        ]}
                        alt="formation"
                        size="is-4by3"
                    />
                </div>
                <div className="column">
                    <BoxTitle title="Formatrice de Psycho Praticiens"/>
                    <div className="columns">
                        <div className="column is-one-half is-offset-1">
                            <a href="https://gestalt-iffp.fr/les-formateurs/">
                                <Card>
                                    <CardContent>
                                        <p className="title is-5">à l'IFPP :</p>
                                    </CardContent>
                                    <CardImage src="gestalt-IFPP" alt="logo IFPP" hoverable="true" size="is-3by1" />
                                </Card>
                            </a>
                        </div>
                        <div className="column is-one-half is-offset-1">
                            <a href="https://www.institut-ayana.fr/formation/formation-la-therapie-de-couple/">
                                <Card>
                                    <CardContent>
                                        <p className="title is-5">au centre AYANA :</p>
                                    </CardContent>
                                    <CardImage src="logoayana" alt="logo Ayana" hoverable="true" size="is-3by1" />
                                </Card>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    </Layout>;

export default IndexPage;
